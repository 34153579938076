import './App.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import 'bootstrap/dist/css/bootstrap.css';

export default function App() {
    return (
        <div className="App d-flex flex-column min-vh-100">
            <h1>Site en refonte...</h1>
        </div>
    );
}
